const BarRating = function () {
  let self = this;

  // wrap element in a wrapper div
  const wrapElement = function () {
    let classes = ['br-wrapper'];
    if (self.options.theme !== '') {
      classes.push('br-theme-' + self.options.theme);
    }
    let wrap = document.createElement('div');
    let parent = self.elem.parentNode;
    wrap.classList.add(...classes);
    wrap.appendChild(self.elem);
    parent.prepend(wrap);
  };

  // find option by value
  const findOption = function (value) {
    if (typeof value === 'number') {
      value = Math.floor(value);
    }

    return self.elem.querySelector('option[value="' + value + '"]');
  };

  // get data
  const getData = function (key) {
    const data = self.elem.data && self.elem.data.hasOwnProperty('barrating') ? self.elem.data.barrating : {};
    if (typeof key !== 'undefined') {
      return data[key];
    }

    return data;
  };

  // set data
  const setData = function (key, value) {
    if (value !== null && typeof value === 'object') {
      self.elem.data = { barrating: value };
    } else {
      let data = self.elem.data && self.elem.data.hasOwnProperty('barrating') ? self.elem.data.barrating : {};
      data[key] = value;
      self.elem.data.barrating = data;
    }
  };

  // save data on element
  const saveDataOnElement = function () {
    const allowEmpty = self.options.allowEmpty !== null ? self.options.allowEmpty : true;

    setData(null, {
      userOptions: self.options,
      ratingValue: '',
      ratingText: '',
      originalRatingValue: '',
      originalRatingText: '',
      allowEmpty: allowEmpty,
      emptyRatingValue: '',
      emptyRatingText: '',
      readOnly: self.options.readonly,
      ratingMade: false,
    });
  };

  // return current rating text
  const ratingText = function () {
    return getData('ratingText');
  };

  // return current rating value
  const ratingValue = function () {
    return getData('ratingValue');
  };

  // build widget and return jQuery element
  const buildWidget = function () {
    let w = document.createElement('div');
    w.classList.add('br-widget');
    self.elem.querySelectorAll('option').forEach(function (option) {
      let val, text, html, a;
      val = option.value;
      if (val !== getData('emptyRatingValue')) {
        text = option.text;
        let a = document.createElement('a');
        a.href = '#';
        a.setAttribute('data-rating-value', val);
        a.setAttribute('data-rating-text', text);
        a.innerHTML = "<span class='sr-only'>" + text + '</span>';

        w.appendChild(a);
      }
    });

    return w;
  };

  // set the value of the select field
  const setSelectFieldValue = function (value) {
    self.elem.selectedIndex = findOption(value).index;
    self.elem.dispatchEvent(new Event('change'));
  };

  // reset select field
  const resetSelectField = function () {
    self.elem.selectedIndex = -1;
    self.elem.dispatchEvent(new Event('change'));
  };

  // remove all classes from elements
  const resetStyle = function () {
    let regx = new RegExp('\\bbr-[^ ]*[ ]?\\b', 'g');
    self.widget.querySelectorAll('a').forEach(function (anchor) {
      anchor.className = anchor.className.replace(regx, '');
    });
  };

  // apply style by setting classes on elements
  const applyStyle = function () {
    let a = self.widget.querySelector('a[data-rating-value="' + ratingValue() + '"]');
    resetStyle();
    // add classes
    if (a) {
      a.classList.add('br-selected', 'br-current');
    }
    document.querySelectorAll('a[data-rating-value]').forEach(function (star) {
      if (star.getAttribute('data-rating-value') < ratingValue()) {
        star.classList.add('br-selected');
      }
    });
  };

  // check if the element is deselectable?
  const isDeselectable = function (element) {
    if (!getData('allowEmpty') || !getData('userOptions').deselectable) {
      return false;
    }

    return ratingValue() == element.getAttribute('data-rating-value');
  };

  // handle click events
  const attachClickHandler = function (elements) {
    elements.forEach(function (element) {
      element.addEventListener('click', function (event) {
        let a = this,
          options = getData('userOptions'),
          value,
          text;

        event.preventDefault();
        value = a.getAttribute('data-rating-value');
        text = a.getAttribute('data-rating-text');
        if (isDeselectable(a)) {
          value = getData('emptyRatingValue');
          text = getData('emptyRatingText');
        }
        setData('ratingValue', value);
        setData('ratingText', text);
        setData('ratingMade', true);
        setSelectFieldValue(value);
        applyStyle();
        options.onSelect.call(self, ratingValue(), ratingText(), event);

        return false;
      });
    });
  };

  // handle mouseenter events
  const attachMouseEnterHandler = function (elements) {
    elements.forEach(function (element) {
      element.addEventListener('mouseenter', function () {
        let a = this;
        resetStyle();
        a.classList.add('br-active');
        const currentValue = a.getAttribute('data-rating-value');
        self.widget.querySelectorAll('a[data-rating-value]').forEach(function (star) {
          if (star.getAttribute('data-rating-value') < currentValue) {
            star.classList.add('br-active');
          }
        });
      });
    });
  };

  // handle mouseleave events
  const attachMouseLeaveHandler = function (elements) {
    self.widget.addEventListener('mouseleave', function () {
      applyStyle();
    });
    self.widget.addEventListener('blur', function () {
      applyStyle();
    });
  };

  // somewhat primitive way to remove 300ms click delay on touch devices
  // for a more advanced solution consider setting `fastClicks` option to false
  // and using a library such as fastclick (https://github.com/ftlabs/fastclick)
  const fastClicks = function (elements) {
    elements.forEach(function (element) {
      element.addEventListener('touchstart', function (event) {
        event.preventDefault();
        event.stopPropagation();
        element.click();
      });
    });
  };

  // disable clicks
  const disableClicks = function (elements) {
    elements.forEach(function (element) {
      element.addEventListener('click', function (event) {
        event.preventDefault();
      });
    });
  };

  const attachHandlers = function (elements) {
    attachClickHandler(elements);
    if (self.options.hoverState) {
      attachMouseEnterHandler(elements);
      attachMouseLeaveHandler(elements);
    }
  };

  const detachHandlers = function (elements) {
    elements.forEach(function (element) {
      element.parentNode.replaceChild(element.cloneNode(true), element);
    });
  };

  const setupHandlers = function (readonly) {
    let elements = self.widget.querySelectorAll('a');
    if (fastClicks) {
      fastClicks(elements);
    }
    if (readonly) {
      detachHandlers(elements);
      disableClicks(self.widget.querySelectorAll('a'));
    } else {
      attachHandlers(elements);
    }
  };

  this.show = function () {
    // run only once
    if (Object.keys(getData()).length !== 0) return;
    wrapElement();
    saveDataOnElement();
    self.widget = buildWidget();
    self.elem.parentNode.appendChild(self.widget);
    applyStyle();
    setupHandlers(self.options.readonly);
    self.elem.style.display = 'none';
  };

  this.readonly = function (state) {
    if (typeof state !== 'boolean' || getData('readOnly') == state) return;
    setupHandlers(state);
    setData('readOnly', state);
    self.widget.classList.toggle('br-readonly');
  };

  this.clear = function () {
    let options = getData('userOptions');
    setData('ratingValue', getData('originalRatingValue'));
    setData('ratingText', getData('originalRatingText'));
    setData('ratingMade', false);
    resetSelectField();
    applyStyle();
    options.onClear.call(this, ratingValue(), ratingText());
  };

  this.init = function (elem, options) {
    const defaults = {
      theme: '',
      initialRating: null, // initial rating
      allowEmpty: null, // allow empty ratings?
      emptyValue: '', // this is the expected value of the empty rating
      showValues: false, // display rating values on the bars?
      showSelectedRating: true, // append a div with a rating to the widget?
      deselectable: true, // allow to deselect ratings?
      reverse: false, // reverse the rating?
      readonly: false, // make the rating ready-only?
      fastClicks: true, // remove 300ms click delay on touch devices?
      hoverState: true, // change state on hover?
      silent: false, // supress callbacks when controlling ratings programatically
      onSelect: function (value, text, event) {}, // callback fired when a rating is selected
      onClear: function (value, text) {}, // callback fired when a rating is cleared
    };
    this.elem = elem;
    this.options = Object.assign({}, defaults, options);
  };
};

export { BarRating };
