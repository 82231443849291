import { BarRating } from './bar-rating';
import './styles.scss';
Bugsnag = window.Bugsnag;

function AjaxRatingForm(form) {
  this.formElement = form;
  this.markElement = form.elements['mark'];
  this.messageContainer = form.querySelector('[data-rating-messages-list] li');
  this.spinner = form.querySelector('[data-rating-spinner]');
  // holds all related star rating html container which need to be updated after a successful rating
  this.relatedRatingStarsContainer = document.querySelectorAll(
    '[data-related-ratings=' + form.getAttribute('data-related-ratings') + ']:not(form)'
  );
  const formInstance = this;
  this.barRating = new BarRating();
  this.barRating.init(this.markElement, {
    theme: 'fontawesome-stars',
    showSelectedRating: false,
    onSelect: function (value, text, event) {
      if (typeof event !== 'undefined') {
        this.readonly(true);
        formInstance.sendRating();
      }
    },
  });
}

AjaxRatingForm.prototype = {
  constructor: AjaxRatingForm,
  init: function () {
    this.barRating.show();
  },
  sendRating: function () {
    this.resetElementClasses();
    this.showSpinner();

    const ajaxCallUrl =
      this.formElement.dataset.ajaxAction +
      '?formName=' +
      this.formElement.name +
      '&displayType=' +
      this.formElement.dataset.displayType;
    const form = this;
    const options = {
      method: 'POST',
      credentials: 'same-origin',
      body: new FormData(this.formElement),
    };

    fetch(ajaxCallUrl, options)
      .then(function (response) {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        response.json().then(function (data) {
          if (response.status === 200) {
            form.markAsValid(data.message);
            form.updateRelatedRatingSpots(data.ratingAvarage, data.ratingCount);
          } else if (data.message) {
            form.markAsInvalid(data.message);
          }
        });
      })
      .catch(function (error) {
        Bugsnag.notify(error);
        form.markAsInvalid(window.ratingErrorMessage);
        this.barRating.clear();
        this.barRating.readonly(false);
      })
      .finally(function () {
        form.hideSpinner();
      });
  },
  markAsValid: function (message) {
    this.messageContainer.innerHTML = message;
    this.messageContainer.classList.add('text-success');
  },
  markAsInvalid: function (message) {
    this.messageContainer.innerHTML = message;
    this.messageContainer.classList.add('text-danger');
  },
  resetElementClasses: function () {
    this.messageContainer.innerHTML = '';
    this.messageContainer.classList.remove('text-danger', 'text-success');
  },
  showSpinner: function () {
    this.spinner.classList.remove('d-none');
  },
  hideSpinner: function () {
    this.spinner.classList.add('d-none');
  },
  updateRelatedRatingSpots: function (average, count) {
    Array.prototype.forEach.call(this.relatedRatingStarsContainer, function (starsContainer) {
      let starsChildren = starsContainer.children[0];
      Array.prototype.forEach.call(starsChildren.children[0].children, function (star) {
        if (average >= 1) {
          star.className = star.className.replace(/fas fa-star-half-alt|far fa-star/, 'fas fa-star');
        } else if (average < 0.5) {
          star.className = star.className.replace(/fas fa-star-half-alt|fas fa-star/, 'far fa-star');
        } else {
          star.className = star.className.replace(/fas fa-star|far fa-star/, 'fas fa-star-half-alt');
        }
        average--;
      });

      const starCount = starsContainer.querySelector('.anw-rating-text');
      if (starCount) {
        starCount.innerHTML = '(' + count + ')';
      }
      starsContainer.classList.remove('d-none');
    });
  },
};

document.addEventListener('DOMContentLoaded', function () {
  let selector = '.jsAjaxRating';
  Array.prototype.forEach.call(document.querySelectorAll(selector), function (form) {
    let ajaxRatingForm = new AjaxRatingForm(form);
    ajaxRatingForm.init();
  });
});
